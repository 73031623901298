#coole-zahl {
    color: #6EC1E4;
    font-size: 4rem;
}

.swimmer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background-color: transparent;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: relative;
}

.swimmer-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    z-index: -1;
}

.swimmer-svg {
    width: 100px;
    height: 100px;
}

.swimmer-container:hover::before {
    background-color: #6EC1E4;
}

.swimmer-svg .circle-bg {
    stroke: #6EC1E4;
    transition: stroke-width 0.3s, stroke 0.3s;
}

.swimmer-svg path {
    fill: #6EC1E4;
    transition: fill 0.3s;
}

.swimmer-svg:hover .circle-bg {
    stroke: #FFAF20;
    animation: pulseRing 1s infinite;
}

.swimmer-svg:hover path {
    fill: #FFAF20;
    animation: pulseSwimmer 1s infinite;
}

@keyframes pulseRing {
    0%, 100% { stroke-width: 4px; }
    50% { stroke-width: 6px; }
}

@keyframes pulseSwimmer {
    0%, 100% { fill-opacity: 1; }
    50% { fill-opacity: 0.8; }
}

.progress-container {
    background-color: #e6e6e6;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
    height: 30px;
}

.progress-bar {
    width: 86%;
    height: 100%;
    background-color: #6EC1E4;
    border-radius: 3px 0 0 3px;
    text-align: right;
    padding-right: 10px;
    box-shadow: none;
    transition: width 2s;
}



.progress-bar span {
    color: white;
    font-weight: bold;
    line-height: 30px;
}

.progress-title {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #6EC1E4;
}

.calendar-table {
    background-color: transparent;
}

.calendar-table th, .calendar-table td {
    background-color: transparent;
}


