.header-background {
    background-color: #10455B;
}
.site-title h4 {
    font-size: 1.5rem;
    margin-bottom: 0;
    color: white;
    line-height: 1.2;
}
.site-title small {
    display: block;
    font-size: 1rem;
    line-height: 1.2;
    color: white;
}
.navbar .navbar-nav .nav-link {
    color: white;
    margin-right: 15px;
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus {
    color: #FFAF20;
}
.navbar .navbar-nav .active .nav-link,
.navbar .navbar-nav .nav-link.active {
    color: #00C2FF;
}
.logo {
    height: 90px;
}
@media (min-width: 992px) {
    .site-title {
        margin-left: 0px;
    }
}