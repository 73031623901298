form {
    max-width: 400px;
    margin: 0 auto;
}

label {
    display: block;
    margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: transparent;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    border-color: #FFAF20;
    outline: none;
}

textarea {
    resize: vertical;
}

input[type="submit"] {
    background-color: #00C2FF;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

input[type="submit"]:hover {
    background-color: #FF8C00;
}