html {
    position: relative;
    min-height: 100%;
}

.footer-background {
    background-color: #10455B;
    color: white;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top:50px;
}
footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

